const config = {
  environment: process.env.REACT_APP_ENVIRONMENT,
  awsRegion: process.env.REACT_APP_AWS_REGION,
  apiUrl: process.env.REACT_APP_API_URL,
  accountId: process.env.REACT_APP_BC_ACCOUNT_ID,
  playerId: process.env.REACT_APP_BC_PLAYER_ID,
  livePlayerId: process.env.REACT_APP_BC_LIVE_PLAYER_ID,
  appUrl: process.env.REACT_APP_URL,
  deepLink: process.env.REACT_APP_DEEP_LINK
}

export default config
