import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, { FC, useEffect } from 'react'
import { Button } from '@mui/base'
import { Flex } from '@brightcove/studio-components'

import './PrivacySettings.scss'
import Loading from '../Loading/Loading'
import { cookiePolicyLink } from '../../utils/utils'
import { UserPreferencesObject } from '../../types/userProfile'
import { setUserProfileData } from '../../store/slices/userProfileSlice'
import {
  userCookieFailure,
  userCookieStart,
  userCookieSuccess
} from '../../store/slices/userCookieSlice'
import { userSelector } from '../../store/slices/config/configSelectors'
import { useLazyGetUserProfileQuery } from '../../store/services/user/userProfileApi'
import {
  useGetUserCookieQuery,
  useUpdateUserCookieMutation
} from '../../store/services/user/userCookieApi'
import { RootState } from '../../store'
import { useLocalStorage } from '../../hooks/UIHooks'

interface PrivacySettingsProps {
  onUpdateSettings: { (): void }
}

const PrivacySettings: FC<PrivacySettingsProps> = ({ onUpdateSettings }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const [getProfileData] = useLazyGetUserProfileQuery()
  const [updateCookieDetails] = useUpdateUserCookieMutation()
  const { data } = useGetUserCookieQuery()
  const { userCookieLoading } = useSelector((state: RootState) => state.userCookie)
  const [, setCookiesSettingsSession] = useLocalStorage('cookies_settings', '')
  const { i18n } = useTranslation()
  const i18nLang = i18n.resolvedLanguage?.toLowerCase()

  const openDetails = (className) => {
    const divItem = document.getElementsByClassName(className)[0]
    if (divItem.classList.contains('hide_p_settings')) {
      divItem.classList.remove('hide_p_settings')
    } else {
      divItem.classList.add('hide_p_settings')
    }
    const detailDivArray = document.getElementsByClassName('cookie_detail')
    for (let i = 0; i < detailDivArray.length; i++) {
      if (
        !detailDivArray[i].classList.contains('hide_p_settings') &&
        !detailDivArray[i].classList.contains(className)
      ) {
        detailDivArray[i].classList.add('hide_p_settings')
      }
    }
  }

  const checkAllCookies = () => {
    const divItemArray = document.getElementsByClassName('toggle-input')
    for (let i = 0; i < divItemArray.length; i++) {
      const item = divItemArray[i] as HTMLInputElement
      item.checked = true
    }
  }

  useEffect(() => {
    dispatch(userCookieStart())
    if (data) {
      const preferences = data['cookies']
      if (preferences['performace'] && document.getElementsByClassName('analytics-check')[0])
        (document.getElementsByClassName('analytics-check')[0] as HTMLInputElement).checked = true
      if (preferences['functionality'] && document.getElementsByClassName('functionality-check')[0])
        (document.getElementsByClassName('functionality-check')[0] as HTMLInputElement).checked =
          true
      if (preferences['targeting'] && document.getElementsByClassName('targeting-check')[0])
        (document.getElementsByClassName('targeting-check')[0] as HTMLInputElement).checked = true
      if (preferences['advertising'] && document.getElementsByClassName('advert-check')[0])
        (document.getElementsByClassName('advert-check')[0] as HTMLInputElement).checked = true
    }
    dispatch(userCookieSuccess())
  }, [data])

  const saveUserCookiePreference = async () => {
    dispatch(userCookieStart())
    const userCookie: UserPreferencesObject = {
      cookies: {
        performace: (document.getElementsByClassName('analytics-check')[0] as HTMLInputElement)
          .checked,
        functionality: (
          document.getElementsByClassName('functionality-check')[0] as HTMLInputElement
        ).checked,
        targeting: (document.getElementsByClassName('targeting-check')[0] as HTMLInputElement)
          .checked,
        advertising: (document.getElementsByClassName('advert-check')[0] as HTMLInputElement)
          .checked
      }
    }
    try {
      if (user) {
        await updateCookieDetails(userCookie)
        const profileResponse = await getProfileData()
        setCookiesSettingsSession(JSON.stringify(userCookie))
        dispatch(setUserProfileData(profileResponse?.['data']))
        dispatch(userCookieSuccess())
        onUpdateSettings()
      } else {
        setCookiesSettingsSession(JSON.stringify(userCookie))
      }
    } catch (err) {
      dispatch(userCookieFailure('' + err))
    }
  }

  if (userCookieLoading) {
    return <Loading />
  }

  return (
    <>
      <Flex className='privacy_settings_container'>
        <div className='privacy_settings'>
          <h2 className='privacy_head'>{t('home.privacy_settings')}</h2>
          <p className='cookie_disclaimer'>{t('home.cookie_disclaimer')}</p>
          <Flex flexDirection='row'>
            <p className='cookie_more_info'>{t('home.more_info')}</p>
            <p
              className='cookie_cookie_policy'
              onClick={() => {
                window.open(cookiePolicyLink(i18nLang || ''), '_blank', 'noreferrer')
              }}
            >
              {t('home.cookie_policy')}
            </p>
          </Flex>
          <h2 className='consent'>{t('home.consent')}</h2>
          <Flex flexDirection='column'>
            <div
              className='cookie_bg'
              onClick={() => {
                openDetails('strict_cookie')
              }}
            >
              <Flex className='accordion' flexDirection='row'>
                <Flex className='cookie_bg_child' flexDirection='column'>
                  <button className='cookie_label'>{t('home.strict_cookies')}</button>
                  <p className='cookie_detail strict_cookie hide_p_settings'>
                    {t('home.strict_cookie_detail')}
                  </p>
                </Flex>
                <label className={i18nLang === 'en' ? 'cookie_button_label' : 'cookie_button_label_th'}>{t('home.always_enabled')}</label>
              </Flex>
            </div>
            <div
              className='cookie_bg'
              onClick={() => {
                openDetails('analytic_cookie')
              }}
            >
              <Flex className='accordion' flexDirection='row'>
                <Flex className='cookie_bg_child' flexDirection='column'>
                  <button className='cookie_label'>{t('home.analytic_cookie')}</button>
                  <p className='cookie_detail analytic_cookie hide_p_settings'>
                    {t('home.analytic_cookie_detail')}
                  </p>
                </Flex>
                <div className='toggle_holder'>
                  <label className='toggle'>
                    <input className='analytics-check toggle-input' type='checkbox' />
                    <span className='toggle-label' data-off='' data-on=''></span>
                    <span className='toggle-handle'></span>
                  </label>
                </div>
              </Flex>
            </div>
            <div
              className='cookie_bg'
              onClick={() => {
                openDetails('functional_cookie')
              }}
            >
              <Flex className='accordion' flexDirection='row'>
                <Flex className='cookie_bg_child' flexDirection='column'>
                  <button className='cookie_label'>{t('home.functionality_cookie')}</button>
                  <p className='cookie_detail functional_cookie hide_p_settings'>
                    {t('home.functionality_cookie_detail')}
                  </p>
                </Flex>
                <div className='toggle_holder'>
                  <label className='toggle'>
                    <input className='functionality-check toggle-input' type='checkbox' />
                    <span className='toggle-label' data-off='' data-on=''></span>
                    <span className='toggle-handle'></span>
                  </label>
                </div>
              </Flex>
            </div>
            <div
              className='cookie_bg'
              onClick={() => {
                openDetails('target_cookie')
              }}
            >
              <Flex className='accordion' flexDirection='row'>
                <Flex className='cookie_bg_child' flexDirection='column'>
                  <button className='cookie_label'>{t('home.targeting_cookie')}</button>
                  <p className='cookie_detail target_cookie hide_p_settings'>
                    {t('home.targeting_cookie_detail')}
                  </p>
                </Flex>
                <div className='toggle_holder'>
                  <label className='toggle'>
                    <input className='targeting-check toggle-input' type='checkbox' />
                    <span className='toggle-label' data-off='' data-on=''></span>
                    <span className='toggle-handle'></span>
                  </label>
                </div>
              </Flex>
            </div>
            <div
              className='cookie_bg'
              onClick={() => {
                openDetails('advert_cookie')
              }}
            >
              <Flex className='accordion' flexDirection='row'>
                <Flex className='cookie_bg_child' flexDirection='column'>
                  <button className='cookie_label'>{t('home.advertising_cookie')}</button>
                  <p className='cookie_detail advert_cookie hide_p_settings'>
                    {t('home.advertising_cookie_detail')}
                  </p>
                </Flex>
                <div className='toggle_holder'>
                  <label className='toggle'>
                    <input className='advert-check toggle-input' type='checkbox' />
                    <span className='toggle-label' data-off='' data-on=''></span>
                    <span className='toggle-handle'></span>
                  </label>
                </div>
              </Flex>
            </div>
            <Flex className='cookie_submit_button' flexDirection='row'>
              <Button
                id='accept_all_settings'
                className='cookie_button_settings'
                onClick={() => {
                  checkAllCookies()
                }}
              >
                {t('home.accept_all')}
              </Button>
              <Button
                onClick={() => {
                  saveUserCookiePreference()
                }}
                id='confirm_choice_settings'
                className='cookie_button_settings'
              >
                {t('home.confirm_my_choice')}
              </Button>
            </Flex>
          </Flex>
        </div>
      </Flex>
    </>
  )
}

export default PrivacySettings
