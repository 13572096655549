import { styled } from '@mui/material'

import { Image } from '../../assets/images'

const { REACT_APP_ENVIRONMENT } = process.env

const getQRCodeImage = (env: string | undefined) => {
  const lowercaseEnv = env?.toLowerCase()
  if (lowercaseEnv?.includes('development') || lowercaseEnv?.includes('qa')) {
    return Image.QRImageQA
  }
  if (lowercaseEnv?.includes('stage')) {
    return Image.QRImageStage
  }
  if (lowercaseEnv?.includes('production')) {
    return Image.QRImagePROD
  }
}

export const PricingStyle = styled('div')(({ theme }) => ({
  marginTop: 100,
  minHeight: '100vh',
  width: '100%',
  backgroundImage: `url(${Image.PricingBg})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'top center',
  boxShadow: '0px 100px 100px black inset',
  marginBottom: 100,
  ' .mobile_qr': {
    position: 'relative',
    backgroundColor: '#000',
    zIndex: '1',
    ' .mock_image': {
      width: '90%',
      margin: '30px 0',
      minHeight: 250
    },
    ' .choose_plan': {
      color: '#FFF',
      fontWeight: 700,
      fontSize: 18,
      paddingBottom: 30
    },
    ' .deep_link': {
      color: '#FFF',
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      width: '80%',
      margin: 'auto',
      padding: 15,
      borderRadius: 10,
      fontWeight: 800,
      marginBottom: 20
    }
  },
  ' .pricing_container': {
    width: '80%',
    margin: '0 auto',
    ' .pricing_header': {
      padding: 0,
      margin: 0,
      color: '#FFF',
      fontWeight: 700,
      fontSize: 20,
      textAlign: 'center',
      span: {
        color: theme.palette.primary.main
      }
    },
    ' .qr_container': {
      ' .qr_left': {
        ' .qr_image': {
          transform: 'scale(.8) translateY(-30px)',
          width: 'inherit',
          height: 386.05,
          backgroundImage: `url(${getQRCodeImage(REACT_APP_ENVIRONMENT)})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '90%',
          backgroundPosition: 'center',
          position: 'relative',
          ' >.corner': {
            background: 'rgba(0,0,0,0)',
            position: 'absolute',
            width: 100,
            border: '2px solid #FFF',
            height: 100,
            ' &.top_left': {
              top: 0,
              left: 0,
              borderRightWidth: 0,
              borderBottomWidth: 0
            },
            ' &.top_right': {
              top: 0,
              right: 0,
              borderLeftWidth: 0,
              borderBottomWidth: 0
            },
            ' &.bottom_left': {
              bottom: 0,
              left: 0,
              borderRightWidth: 0,
              borderTopWidth: 0
            },
            ' &.bottom_right': {
              bottom: 0,
              right: 0,
              borderLeftWidth: 0,
              borderTopWidth: 0
            }
          }
        }
      },
      ' .qr_right': {
        paddingLeft: 80,
        color: '#FFF',
        ' h3': {
          fontWeight: 800,
          ' >.title': {
            color: theme.palette.primary.main
          }
        }
      }
    },
    ' .pricing_terms': {
      color: '#ADADAD',
      fontWeight: 600,
      textAlign: 'center',
      a: {
        color: '#FFF',
        textDecoration: 'underline'
      }
    }
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
    marginBottom: 100,
    ' .deep_link': {
      transform: 'scale(.9)'
    }
  }
}))
