import { getYear } from 'date-fns'

import i18n from './i18n'

let FIREBASE_ERROR_CODE_MAPPING
const LANGUAGES = ['th', 'en']
const NEILSON_APP_ID = 'P581749B4-6972-4AD8-BC31-AF30289BC048'

i18n.on('languageChanged init', () => {
  FIREBASE_ERROR_CODE_MAPPING = {
    'auth/invalid-email': i18n.t('login.invalid_email_tel'),
    'auth/invalid-password': i18n.t('login.password')
  }
})

const LINKS = {
  social: {
    tiktok: 'https://www.tiktok.com/@onedofficialth',
    instagram: 'https://www.instagram.com/onedofficialth',
    youtube: 'https://www.youtube.com/@oned',
    facebook: 'https://www.facebook.com/onedofficialth',
    twitter: 'https://twitter.com/onedofficialth'
  },
  terms: {
    th: 'https://assets.oned.net/doc/term-condition-th.html',
    en: 'https://assets.oned.net/doc/term-condition-en.html'
  },
  privacy: {
    th: 'https://assets.oned.net/doc/privacy-policy-th.html',
    en: 'https://assets.oned.net/doc/privacy-policy-en.html'
  },
  stores: {
    google: 'https://play.google.com/store/apps/details?id=com.gmm.onehd&hl=th',
    apple: 'https://apps.apple.com/th/app/oned/id922370524?l=th'
  }
}

export const YEAR_OF_INTERVAL = {
  startDate: new Date(getYear(new Date()) - 115, 0, 1),
  endDate: new Date(getYear(new Date()) - 16, 11, 31)
}

export { LANGUAGES, LINKS, FIREBASE_ERROR_CODE_MAPPING, NEILSON_APP_ID }

export const charsMaxLength = 25

export const entitlementErrorCodes = ['400.00', 401.01]

export const entitlementPayload = (uid: string, contentId: string): any => ({
  user_id: uid === null ? '' : uid,
  content_id: contentId
})
