import React, { FC, useEffect, useState } from 'react'
import { Grid, useMediaQuery } from '@mui/material'

import { ContentSection } from '../../types/section'
import { theme } from '../../styles/customMUITheme'
import { useLazyGetDynamicStringsQuery } from '../../store/services/dynamicStrings/dynamicStringsApi'
import { googlePublishBannerAds } from '../../hooks/useGooglePublisherTag'
import ThumbnailTile from '../../components/Tile/ThumbnailTile'
import './Content.scss'

interface ContentProps {
  sectionResult?: ContentSection
}

const Content: FC<ContentProps> = ({ sectionResult }) => {
  const [response, setResponse] = useState<[] | JSX.Element[]>([])
  const tempArr: any = []
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'))
  const columnCount = isMobileScreen ? 3 : 6
  const diffRowCount = 4
  const [getAdSlotId]: any = useLazyGetDynamicStringsQuery()

  const insertAds = (responseContent) => {
    const tempContentArray: JSX.Element[] = []
    let tempContent: JSX.Element[] = []
    let prevRowCount = 0
    responseContent?.forEach((item, index) => {
      const rowCount = Math.floor(index / columnCount)
      if (rowCount < diffRowCount || Math.floor(rowCount % diffRowCount) !== 0) {
        tempContentArray.push(...tempContent)
        tempContent = []
        tempContentArray.push(<ThumbnailTile key={index} item={item} showTrailer={true} page={'catalog'}/>)
      }
      if (rowCount >= diffRowCount && Math.floor(rowCount % diffRowCount) === 0) {
        if (prevRowCount != rowCount) {
          prevRowCount = rowCount
          tempContentArray.push(
            <Grid item xs={12} key={index + 'ad_div'} className='full-col-ad-content'>
              <div className='banner_div' id={'bannerDiv' + index}></div>
            </Grid>
          )
        }
        tempContent.push(<ThumbnailTile key={index} item={item} showTrailer={true} page={'catalog'}/>)
      }
    })
    if (tempContent && tempContent.length > 0) {
      tempContentArray.push(...tempContent)
    }
    setResponse(tempContentArray)
  }

  const getAdSlotIdFromMW = async () => {
    const response = await getAdSlotId({
      q: 'web_banner_ad_slot_id'
    })
    return response
  }

  useEffect(() => {
    if (sectionResult?.items) {
      tempArr.push(...sectionResult.items)
      insertAds(tempArr)
    }
  }, [])

  useEffect(() => {
    const adSlotId = async () => {
      const response = await getAdSlotIdFromMW()
      const adIds = document.getElementsByClassName('full-col-ad-content')
      if (adIds != null && adIds.length > 0) {
        const ids: string[] = []
        for (let i = 0; i < adIds.length; i++) {
          const adDiv = adIds[i].children[0]
          ids.push(adDiv.id)
        }
        if (response && response.data && response.data.results && response.data.results.items) {
          if (response.data.results.items.length > 0) {
            googlePublishBannerAds(response.data.results.items[0].value, ids)
          }
        }
      }
    }
    adSlotId()
  }, [sectionResult])

  return (
    <Grid className='content-margin-bottom' container spacing={2}>
      {response}
    </Grid>
  )
}

export default Content
