import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';

import { maintenanceMessageSelector } from "../../store/slices/config/configSelectors";

import './Maintenance.scss';

const Maintenance: FC = () => {
  const { t } = useTranslation();
  
  let maintenanceMessage = useSelector(maintenanceMessageSelector);
  if (maintenanceMessage == null) {
    maintenanceMessage = t('maintenance.description');
  }

  return (
    <div className='maintenance-page'>
      <div className='maintenance-page-content'>
        <div className='maintenance-page-description'>{maintenanceMessage}</div>
      </div>
    </div>
  );
};

export default Maintenance;
