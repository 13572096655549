import React, { FC } from 'react';
import classnames from 'classnames';
import './Loading.scss';

interface LoadingProps {
  className?: string;
}

const Loading: FC<LoadingProps> = ({ className }) => {
  const loadingClassName = classnames('Loading-indicator', className);

  return (
    <div className={loadingClassName}>
      <p className='spinner'></p>
    </div>
  );
};

export default Loading;
