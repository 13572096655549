import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { UserProfile } from '../../types/userProfile'

interface UserProfileState {
  data: UserProfile | null | undefined
  loading: boolean
  error: string | null
  dayDisable?: boolean
  isMonthChange?: boolean
  lastDayOfMonth?: number
  isLogged: string
}

const initialState: UserProfileState = {
  data: {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    gender: '',
    date: '',
    month: '',
    year: '',
    date_of_birth: '',
    favorites: [],
    watching: [],
    preferences: {
      cookies: {
        performace: false,
        functionality: false,
        targeting: false,
        advertising: false
      }
    }
  },
  loading: false,
  error: null,
  dayDisable: false,
  isMonthChange: false,
  lastDayOfMonth: 0,
  isLogged: ''
}

const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    setUserProfileData: (state, action: PayloadAction<UserProfile | null | undefined>) => {
      state.data = action.payload
      state.isLogged = 'Y'
    },
    setUserProfileLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setUserProfileError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload
    },
    setDayDisable: (state, action: PayloadAction<boolean>) => {
      state.dayDisable = action.payload
    },
    setIsMonthChange: (state, action: PayloadAction<boolean>) => {
      state.isMonthChange = action.payload
    },
    setLastDatOfMonth: (state, action: PayloadAction<number>) => {
      state.lastDayOfMonth = action.payload
    },
    setUserStatus: (state, action: PayloadAction<string>) => {
      state.isLogged = action.payload
    }
  }
})

export const {
  setUserProfileData,
  setUserProfileLoading,
  setUserProfileError,
  setDayDisable,
  setIsMonthChange,
  setLastDatOfMonth,
  setUserStatus
} = userProfileSlice.actions

export default userProfileSlice.reducer
