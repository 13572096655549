import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import OpenApp from 'react-open-app'
import { Trans, useTranslation } from 'react-i18next'
import React, { FC, useEffect } from 'react'
import { Grid, Typography } from '@mui/material'

import { LinkText } from '../LinkText/LinkText'
import { useLazyGetUserProfileQuery } from '../../store/services/user/userProfileApi'
import { RootState } from '../../store'
import { useBreakPoints } from '../../hooks/useBreakPoints'

import { PricingStyle } from './Pricing.style'
import PackageItems from './Components/PackageItems'
import { PackageData } from './Components/PackageData'

const {
  REACT_APP_DEEP_LINK,
  REACT_APP_DEEP_LINK_ANDROID,
  REACT_APP_DEEP_LINK_IOS,
  REACT_APP_PLAYSTORE_ANDROID,
  REACT_APP_PLAYSTORE_IOS
} = process.env

const Pricing: FC = () => {
  const plans = PackageData()
  const { i18n, t } = useTranslation()
  const { isMobile, isTablet } = useBreakPoints()
  const isIOs = navigator.userAgent.includes('Mac')
  const { currentLocation } = useSelector((state: RootState) => state.menu)
  const [getProfileData] = useLazyGetUserProfileQuery()
  const playStoreUrl = `${isIOs ? REACT_APP_PLAYSTORE_IOS : REACT_APP_PLAYSTORE_ANDROID}`
  const navigate = useNavigate()

  const termsUrl = `https://assets.oned.net/doc/term-condition-${i18n.language}.html`

  useEffect(() => {
    checkSubscription()
  }, [])

  const routeChange = () => {
    // setIsGTMSignUpSuccessful(true)
    // const path = `/`
    setTimeout(() => {
      navigate(currentLocation as string)
      // dispatch(setStep(1))
      // sessionStorage.setItem('shouldNavigateBack', 'true')
    }, 0)
  }

  const checkSubscription = () => {
    getProfileData().then((profileResponse) => {
      if (profileResponse?.data?.subscription?.subscription_status?.toLowerCase() === 'active') {
        routeChange()
      }
    })
  }
  return (
    <PricingStyle className='pricing'>
      {isMobile || isTablet ? (
        <Grid className='mobile_qr' justifyContent='center' alignItems='center' textAlign='center'>
          <Grid>
            <img
              src='https://assets.oned.net/imgs/oned_premium_mockup.png'
              alt=''
              className='mock_image'
            />
          </Grid>
          <Grid>
            <Typography className='choose_plan'>{t('pricing.choose_plan')}</Typography>
            <OpenApp
              className='deep_link'
              href={playStoreUrl}
              android={`${REACT_APP_DEEP_LINK}/${REACT_APP_DEEP_LINK_ANDROID}`}
              ios={`${REACT_APP_DEEP_LINK}/${REACT_APP_DEEP_LINK_IOS}`}
            >
              {t('pricing.click_here')}
            </OpenApp>
          </Grid>
          <PackageItems plans={plans} />
        </Grid>
      ) : (
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
          direction='column'
          spacing={5}
          className='pricing_container'
        >
          <Grid
            item
            container
            direction='row'
            justifyContent='center'
            className='qr_container'
            flexWrap='nowrap'
          >
            <Grid item flexWrap='nowrap' width='393px' className='qr_left'>
              <Grid className='qr_image'>
                <div className='corner top_left' />
                <div className='corner top_right' />
                <div className='corner bottom_left' />
                <div className='corner bottom_right' />
              </Grid>
              <Grid item>
                <Typography variant='body1' className='pricing_header'>
                  <Trans i18nKey='pricing.scan_header' components={{ title: <span /> }} />
                </Typography>
              </Grid>
            </Grid>
            <Grid className='qr_right'>
              <Typography variant='h3'>
                <Trans
                  i18nKey='pricing.oned_premium'
                  components={{ title: <div className='title' /> }}
                />
              </Typography>
              <Typography>{t('pricing.more_fun')}</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant='body1' className='pricing_terms'>
              <Trans
                i18nKey='pricing.terms'
                components={{
                  terms: <a href={termsUrl} target='_blank' rel='noreferrer' />,
                  privacy: <LinkText linkKey='privacy' textKey='legal.privacy' defaultText='' />
                }}
              />
            </Typography>
          </Grid>
          <Grid item flex={1} width={'100%'}>
            <PackageItems plans={plans} />
          </Grid>
        </Grid>
      )}
    </PricingStyle>
  )
}

export default Pricing
